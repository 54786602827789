<template>
  <div>
    <div class="exhibition">
      <div class="w-full">
        <div class="main main-animation">
          <div class="main-img main-animation-image">
            <img src="../../public/summer_71.png" alt="Auctions">
          </div>
          <button @click="scrollToBottom" class="main-btn main-btn-animation">Make a bid</button>
        </div>
        <div class="content">
          <div class="wrapper">
            <div class="content-wrapper">
              <h2 class="content-title text-animation animation-top content-animation">How it works</h2>
              <div class="content-cont">
                <div class="content-cont__img animation-top order-block-1">
                  <img src="../../public/1.png" alt="">
                </div>
                <div class="content-cont__info right animation-top order-block-2">
                  <h3 class="content-cont__subtitle text-animation">Step 1</h3>
                  <p class="content-cont__desc text-animation">
                    Connect your wallet to be able to participate in the auction, increase your bids and win NFTs
                  </p>
                </div>
                <div class="content-cont__info left animation-top order-block-3">
                  <h3 class="content-cont__subtitle text-animation">Step 2</h3>
                  <p class="content-cont__desc text-animation">
                    Go to the auction page (link for 1 year, 10 years, 100 years).
                  </p>
                </div>
                <div class="content-cont__img animation-top order-block-4">
                  <img src="../../public/2.png" alt="">
                </div>
                <div class="content-cont__img animation-top order-block-5">
                  <img src="../../public/3.png" alt="">
                </div>
                <div class="content-cont__info right animation-top order-block-6">
                  <h3 class="content-cont__subtitle text-animation auctions-title-animation-start">Step 3</h3>
                  <p class="content-cont__desc text-animation">
                    Place your bid in the selected auction by choosing the place you would like to be in or the amount of theses you would like to use for the current bid
                  </p>
                </div>
                <div class="content-cont__info right animation-top order-block-7">
                  <h3 class="content-cont__subtitle text-animation auctions-title-animation-start">Step 4</h3>
                  <p class="content-cont__desc text-animation">
                    If your bid is knocked out of the winners list, you have the possibility to increase your bid or withdraw theses back to your wallet
                  </p>
                </div>
                <div class="content-cont__img animation-top order-block-8">
                  <img src="../../public/4.png" alt="">
                </div>
                <div class="content-cont__img animation-top order-block-9">
                    <img src="../../public/4_1.png" alt="">
                </div>
                <div class="content-cont__info right animation-top order-block-10">
                      <h3 class="content-cont__subtitle text-animation auctions-title-animation-start">Step 5</h3>
                      <p class="content-cont__desc text-animation">
                          Also, if your bet was knocked out of the winners' list, you can increase your bet by clicking on the Increase button.
                          Your bet will be increased by the difference between the amount you indicated and your old bet.
                      </p>
                  </div>
                <div class="content-cont__info right animation-top order-block-11">
                  <h3 class="content-cont__subtitle text-animation auctions-title-animation-start">Step 6</h3>
                  <p class="content-cont__desc text-animation">
                    When the auction is over in your personal account and on the auction page you will be able to see the 'take NFT back' button if you have won or the Withdraw button if you have lost.
                  </p>
                </div>
                <div class="content-cont__img animation-top order-block-12">
                      <img src="../../public/5.png" alt="">
                  </div>
              </div>
            </div>
          </div>
        </div>

          <div class="auctions" id="auctions">
              <div class="wrapper">
                <div class="auctions-title-animation text-animation">Current Auctions</div>
                <div class="animation-wrapper">
                  <img src="../../public/swipe.gif" alt="" class="cursor" id="cursor-more-info">
                </div>
                <AuctionCard class="auction-card-animation"/>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Timer from "../components/Timer.vue";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CSSPlugin } from 'gsap/CSSPlugin'
import AuctionCard from "@/components/AuctionCard.vue";

gsap.registerPlugin(CSSPlugin);
export default {
  name: 'Home',
  components: {
      AuctionCard,
      // Timer
  },
  data() {
    return {
      auctionFinished100Year: false,
      auctionFinished10Year: false,
      auctionFinished1Year: false,
      frameAnimationNone: false
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted () {
    const cursor = document.getElementById('cursor-more-info')
    const display = window.getComputedStyle(cursor).display
    window.addEventListener("resize", () => {
      if(display === 'block') {
        setTimeout(() => {
          cursor.style.display = 'none';
        },5000)
      }
    });
    if(display === 'block') {
      setTimeout(() => {
        cursor.style.display = 'none';
      },10000)
    }
    this.scrollTrigger()
  },
  methods: {
    handleResize() {
      let width = window.innerWidth;
      if (width < 850) {
        this.frameAnimationNone = false
      } else {
        this.frameAnimationNone = true
      }

    },
    scrollToBottom () {
      window.scrollTo({ left: 0, top: (document.body.scrollHeight - 1500), behavior: "smooth" });
    },
    changeDate (value) {
      if (!value) return ''
      return localStorage.getItem(`${value}`)
    },
    scrollTrigger () {
      gsap.registerPlugin(ScrollTrigger)
      ScrollTrigger.defaults({
        start: '20px 80%',
        end: 'bottom 100px',
        scrub: true,
        // markers: true,
      })

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.main-animation-image',
          start: '20px 40%',
          end: 'bottom 100px',
        },
      });

      tl.to('.main-animation-image', {
        direction: 3,
        y: 0,
        zIndex: 1
      });

      tl.to('.main-animation-image', {
        height: '405px',
        width: "345px",
        borderRadius: '20px',
        y: 30,
        direction: 3,
      });

      tl.to('.main-animation-image', {
        x: -450,
        borderRadius: '20px',
        direction: 3,
      });



      let tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.content-animation',
          start: '200px 90%',
          end: 'bottom 100px',
        },
      });

      tl2.to('.main-btn-animation', {
        x: -450,
        direction: 1.5,
      });

      tl2.to('.main-btn-animation', {
        direction: 1.5,
      });

      tl2.to('.main-btn-animation', {
        opacity: 1,
        direction: 1.5,
      });

      ScrollTrigger.create({
        trigger: ".content-animation",
        start: "-300px 95%",
        end: "+=1470 20%",
        pin: ".main-animation"
      });
      const texts = document.querySelectorAll('.text-animation')
      const animationTop = document.querySelectorAll('.animation-top')

      Array.from(texts).map((item) => {
        gsap.to(item, {
          scrollTrigger: {
            trigger: item,
          },
          duration: 1.5,
          opacity: 1,
        });
      })

      Array.from(animationTop).map((item) => {
        gsap.to(item, {
          yPercent: -30,
          ease: "none",
          scrollTrigger: {
            trigger: item,
          },
        });
      })
      gsap.to('.auctions-title-animation', {
        scrollTrigger: {
          trigger: '.auctions-title-animation-start',
          end: "+=500",
          start: '150px 80%'
        },
        fontSize: '40px',
        marginTop: '0',
        marginBottom: '30px',
      });


      gsap.from('.auctions-item', {
        scrollTrigger: {
          trigger: '.auctions-title-animation',
        },
        duration: 1.5,
        opacity: 0,
      });
    },
  }
}
</script>
<style scoped lang="scss">
.animation-wrapper {
  height: 35px;
}
.cursor {
  display: none;
  @media (max-width: 768px) {
    display: block;
    height: 45px;
    background-color: transparent;
  }
}

.text-animation {
  opacity: 0;
}

.exhibition {
  overflow: hidden;
}
.main-btn-animation {
  display: block !important;
  opacity: 0;
  margin-top: 60px !important;
}
.w-80 {
  width: 1400px !important;
}
.w-full {
  width: 100%;
  flex-direction: column;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*margin-right: 0;*/
  /*margin-left: 0;*/
}
.main-animation {
  margin-bottom: 300px;
}
.auctions-title-animation {
  margin-top: 120px;
  margin-bottom: 346px;
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  color: #000000;
}
.exhibition {
  .wrapper {
    flex-direction: column;
  }
}
.head {
  padding: 12px 0;
  border-bottom: 1px solid #BBBBBB;
  &-nav {
    margin-left: auto;
    display: flex;
    a {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      margin-left: 50px;
      text-decoration: none;
      transition: .35s;
      svg {
        transition: .35s;
        margin-left: 10px;
      }
      &:hover {
        color: #ED7D37;
        svg {
          path {
            fill: #ED7D37;
          }
        }
      }
    }
  }
}
.header {
  &-info {
    .wrapper {
      text-align: center;
    }
    &.auctions {
      padding: 7px 0;
      font-size: 18px;
      line-height: 21px;
      color: #676767;
      background: #F7F7F7;
      .wrapper {
        justify-content: center;
      }
      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
.main {
  //position: fixed;
  //top: 148px;
  //left: 0;
  width: 100%;
  height: calc(100vh - 148px);
  display: flex;
  flex-direction: column;
  align-items: center;
  &-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #7D888A;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &-btn {
    display: none;
    margin-top: 30px;
    padding: 12px 46px;
    background: #ED7D37;
    border-radius: 5px;
    transition: .35s;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    text-decoration: none;
    &:hover {
      background: #E07735;
    }
  }
}
.content {
  width: 100%;
  &-wrapper {
    margin-left: auto;
    max-width: 730px;
    width: 100%;
  }
  &-title {
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    color: #000000;
  }
  &-cont {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    &__img {
      background: #EBEBEB;
      border-radius: 20px;
      width: 330px;
      height: 255px;

      @media (max-width: 450px) {
        width: 100%;
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &.right {
        text-align: left;
      }
      &.left {
        text-align: right;
      }
    }
    &__subtitle {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: #000000;
      margin-bottom: 8px;
    }
    &__desc {
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }
  }
}
.auctions {
  &:not(.header-info) {
    width: 100%;
    margin: 40px 0 115px;
  }
  &-title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #000000;
  }
  &-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &-auction {
    flex-basis: 30%;
    background: #F9F9F9;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    overflow: hidden;
    &__image {
      width: 100%;
      height: 405px;
      object-fit: cover;
    }
    &__body {
      padding: 0 20px 16px;
    }
    &__name {
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      color: #000000;
      margin: 0;
      padding: 16px 0;
      text-align: center;
      font-weight: normal;
      span {
        font-weight: bold;
      }
    }
    &__timer {
      padding: 12px 0;
      border-top: 1px solid #E1E1E1;
      border-bottom: 1px solid #E1E1E1;
    }
    &__btns {
      padding: 16px 24px 0;
      display: flex;
      justify-content: center;
    }
    &__bid {
      display: block;
      padding: 10px 26px;
      font-size: 18px;
      line-height: 21px;
      color: #FFFFFF;
      background: #ED7D37;
      border-radius: 5px;
      white-space: nowrap;
      text-decoration: none;
      &:hover {
        box-shadow: 0px 0px 10px rgba(237, 125, 55, 0.5);
        padding: 12px 36px;
        margin: -2px -10px -2px;
      }
    }
  }
}
.flip-clock {
  margin-left: -7px !important;
  margin-right: -7px !important;
}

.content-cont__img img{
  max-width: 100%;
}

@media (max-width: 768px) {
  /*.main-auction__image{*/
  /*  height: 200px;*/
  /*}*/

  .content-cont{
    grid-template-columns: 1fr;

    div {
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
      }
      &:nth-child(3) {
        order: 3;
      }
      &:nth-child(4) {
        order: 4;
      }
      &:nth-child(5) {
        order: 6;
      }
      &:nth-child(6) {
        order: 5;
      }
      &:nth-child(7) {
        order: 7;
      }
      &:nth-child(8) {
        order: 8;
      }
      &:nth-child(9) {
        order: 10;
      }
      &:nth-child(10) {
        order: 9;
      }
      &:nth-child(11) {
        order: 11;
      }
      &:nth-child(12) {
        order: 12;
      }
    }

  }
}
</style>
