var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "exhibition"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "main main-animation"
  }, [_vm._m(0), _c('button', {
    staticClass: "main-btn main-btn-animation",
    on: {
      "click": _vm.scrollToBottom
    }
  }, [_vm._v("Make a bid")])]), _vm._m(1), _c('div', {
    staticClass: "auctions",
    attrs: {
      "id": "auctions"
    }
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "auctions-title-animation text-animation"
  }, [_vm._v("Current Auctions")]), _vm._m(2), _c('AuctionCard', {
    staticClass: "auction-card-animation"
  })], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-img main-animation-image"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/summer_71.png"),
      "alt": "Auctions"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "content-wrapper"
  }, [_c('h2', {
    staticClass: "content-title text-animation animation-top content-animation"
  }, [_vm._v("How it works")]), _c('div', {
    staticClass: "content-cont"
  }, [_c('div', {
    staticClass: "content-cont__img animation-top order-block-1"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/1.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "content-cont__info right animation-top order-block-2"
  }, [_c('h3', {
    staticClass: "content-cont__subtitle text-animation"
  }, [_vm._v("Step 1")]), _c('p', {
    staticClass: "content-cont__desc text-animation"
  }, [_vm._v(" Connect your wallet to be able to participate in the auction, increase your bids and win NFTs ")])]), _c('div', {
    staticClass: "content-cont__info left animation-top order-block-3"
  }, [_c('h3', {
    staticClass: "content-cont__subtitle text-animation"
  }, [_vm._v("Step 2")]), _c('p', {
    staticClass: "content-cont__desc text-animation"
  }, [_vm._v(" Go to the auction page (link for 1 year, 10 years, 100 years). ")])]), _c('div', {
    staticClass: "content-cont__img animation-top order-block-4"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/2.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "content-cont__img animation-top order-block-5"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/3.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "content-cont__info right animation-top order-block-6"
  }, [_c('h3', {
    staticClass: "content-cont__subtitle text-animation auctions-title-animation-start"
  }, [_vm._v("Step 3")]), _c('p', {
    staticClass: "content-cont__desc text-animation"
  }, [_vm._v(" Place your bid in the selected auction by choosing the place you would like to be in or the amount of theses you would like to use for the current bid ")])]), _c('div', {
    staticClass: "content-cont__info right animation-top order-block-7"
  }, [_c('h3', {
    staticClass: "content-cont__subtitle text-animation auctions-title-animation-start"
  }, [_vm._v("Step 4")]), _c('p', {
    staticClass: "content-cont__desc text-animation"
  }, [_vm._v(" If your bid is knocked out of the winners list, you have the possibility to increase your bid or withdraw theses back to your wallet ")])]), _c('div', {
    staticClass: "content-cont__img animation-top order-block-8"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/4.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "content-cont__img animation-top order-block-9"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/4_1.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "content-cont__info right animation-top order-block-10"
  }, [_c('h3', {
    staticClass: "content-cont__subtitle text-animation auctions-title-animation-start"
  }, [_vm._v("Step 5")]), _c('p', {
    staticClass: "content-cont__desc text-animation"
  }, [_vm._v(" Also, if your bet was knocked out of the winners' list, you can increase your bet by clicking on the Increase button. Your bet will be increased by the difference between the amount you indicated and your old bet. ")])]), _c('div', {
    staticClass: "content-cont__info right animation-top order-block-11"
  }, [_c('h3', {
    staticClass: "content-cont__subtitle text-animation auctions-title-animation-start"
  }, [_vm._v("Step 6")]), _c('p', {
    staticClass: "content-cont__desc text-animation"
  }, [_vm._v(" When the auction is over in your personal account and on the auction page you will be able to see the 'take NFT back' button if you have won or the Withdraw button if you have lost. ")])]), _c('div', {
    staticClass: "content-cont__img animation-top order-block-12"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/5.png"),
      "alt": ""
    }
  })])])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animation-wrapper"
  }, [_c('img', {
    staticClass: "cursor",
    attrs: {
      "src": require("../../public/swipe.gif"),
      "alt": "",
      "id": "cursor-more-info"
    }
  })]);
}]

export { render, staticRenderFns }